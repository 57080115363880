<template>
  <div class="pageContent">
    <searchHeader />
    <div class="productionSell">
      <div class="titleTab">
          <p :class="{tabActive:isTabActive}" @click="isTabActive=true">供应</p>
          <p :class="{tabActive:!isTabActive}" @click="isTabActive=false">求购</p>
      </div>
      <div class="productionList" v-show="!isTabActive">
        <div class="pagination" >
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalPage"
          ></el-pagination>
      </div>
        <div class="production" v-for="(item,index) of resourceDatas" :key="index">
           <div class="infoGren">
              <p class="className">{{item.commodityName}} </p>
              <p>发布时间：{{item.createTime | DateFormateDay }}</p>
           </div>
           <div class="weigInfo">
              <p>交货地：{{item.address}}</p>
           </div>
           <div class="weigInfo">
              <p>联系人：{{item.contactsName}} &nbsp;{{item.contactsPhone}}</p>
           </div>
           <p class="status" v-if="item.adminSeq!=9999">
              <i class="leftAngle"></i>
              <span class="statusText">推荐</span>
            </p>
        </div>
      </div>
      <div class="productionList" v-show="isTabActive">
        <div class="pagination" >
          <el-pagination
            @size-change="handleSellSizeChange"
            @current-change="handleSellCurrentChange"
            :current-page="sellCurrent"
            :page-size="sellPageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalSellPage"
          ></el-pagination>
        </div>
        <div class="productionSells" v-for="(item,index) of sellResourceDatas" :key="index">
           <div class="sellContent">
              <div class="weigInfo">
                  <p class="enterName"><img src="../../assets/images/homePage/shop.png" alt="" width="20px" class="imgCla">{{item.companyName}}</p>
                  <p class="tagTitle"><span>认证</span><span>{{item.packages=='现货'?'现货':''}}</span></p>
                  <p class="newTel"><img src="../../assets/images/homePage/newTel.png" alt="" width="15px" class="imgCla">{{item.contactsPhone}}</p>
              </div>
              <el-tooltip class="item" effect="light" :content="item.commodityName" placement="top" :disabled="item.commodityName.length<20">
                <p class="marginReight goodName">{{ item.commodityName }}</p>
              </el-tooltip>
              <p class="marginReight prices">{{item.price}}</p>
              <el-tooltip class="item" effect="light" :content="item.address+''+item.specifications" placement="top" :disabled="(item.address.length+item.specifications.length)<16">
                <p class="marginReight posit">{{item.address}}&emsp; {{item.specifications}}</p>
              </el-tooltip>
              
           </div>
           <p class="status" v-if="item.adminSeq!=9999">
              <i class="leftAngle"></i>
              <span class="statusText">推荐</span>
            </p>
        </div>
      </div>
    </div>
    <!-- 查看联系方式弹窗 -->
    <orderDialog ref="orderDialog" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import searchHeader from "../base/header/searchHeader";
import orderDialog from "./orderDialog";
export default {
  data() {
    return {
      isTabActive:true,
      current: 1,
      pageSize: 10,
      totalPage: null,
      totalSellPage:null,
      sellCurrent: 1,
      sellPageSize: 10,
      sort: [],
      resourceDatas: [],
      sellResourceDatas:[],
      categoryOptions: []
    };
  },
  components: {
    searchHeader,
    orderDialog
  },
  computed: {
    ...mapGetters(["sessionInfoGetter", "userTypeGetter"])
  },
  mounted() {
    this.getDatas();
    this.getSellDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    handleSellSizeChange(val) {
      this.pageSize = val;
      this.getSellDatas();
    },
    handleSellCurrentChange(val) {
      this.current = val;
      this.getSellDatas();
    },
    getDatas() {
      protocolFwd.param_queryCbSupplyNoteNoSession.param = {
        page: this.current - 1,
        size: this.pageSize,
        // sort: [{
        //         property: "price",
        //         direction: "DESC"
        //       }],
        supplyCommodityId: null,
        weightMin: null,
        weightMax: null,
        typeStr: 'B'
      };
      http
        .postFront(protocolFwd.param_queryCbSupplyNoteNoSession)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.resourceDatas = value.content;
            this.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    getSellDatas() {
      protocolFwd.param_queryCbSupplyNoteNoSession.param = {
        page: this.sellCurrent - 1,
        size: this.sellPageSize,
        // sort: [{
        //         property: "price",
        //         direction: "DESC"
        //       }],
        supplyCommodityId: null,
        weightMin: null,
        weightMax: null,
        typeStr: 'S'
      };
      http
        .postFront(protocolFwd.param_queryCbSupplyNoteNoSession)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.sellResourceDatas = value.content;
            this.totalSellPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    showOrderDialog(id) {
      if (this.sessionInfoGetter.isLogin == 'true') {
        this.$refs.orderDialog.showDialog(id);
      } else {
        this.$EL_MESSAGE('请先登录');
        this.$router.push('/login');
      }
    },
    goProductionDetails(id) {
      sessionStorage.setItem('productCode', id)
      this.$router.push({
        name: "supplyDemandDetails",
        params: {
          id: id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>

.productionSell {
  padding: 15px;
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 10px auto;
  box-sizing: border-box;
  color: #787878;
}
.titleTab{
  overflow: hidden;
  margin-bottom:20px;
  background-color:#fff;
}
.titleTab p{
  float: left;
  width:50%;
  line-height: 40px;
  font-size:16px;
  text-align:center;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.titleTab p:nth-child(2){
  border-left:none;
}
.titleTab .tabActive{
  background-color:#cf4640;
  color:#fff;
}
.production .infoGren{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.production .weigInfo{
  line-height: 30px;
}
.imgCla{
  vertical-align: middle;
  margin-right:10px;
}
.newTel{
  line-height: 40px;
  font-size:12px;
}
.tagTitle span{
  display: inline-block;
  width:40px;
  font-size:10px;
  line-height: 20px;
  background-color:rgba(207,70,64,0.2);
  color:#cf4640;
  border-radius:5px;
  margin-right:10px;
  text-align:center;
}
.production,.productionSells{
  padding:20px;
  border:1px solid #ddd;
  position: relative;
  margin-bottom: 10px;
  background-color:#fff;
}
.production:hover,.productionSells:hover{
  box-shadow: 0px 0px 3px 3px #ccc;
  transition: box-shadow 0.5s;
}
.sellContent{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333
}
.className{
  font-size:16px;
  font-weight: bold;
  color:#cf4640;
  line-height: 40px;
}
.sellContent .weigInfo{
  width:30%;
  border-right:1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sellContent .marginReight{
  padding:0 10px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sellContent .goodName{
  font-size:16px;
  width:31%;
}
.sellContent .posit{
  width:24%;
}
.sellContent .prices{
  color:#cf4640;
  font-weight: bold;
  width:14%;
}
.pagination{
  text-align:right;
  padding:10px 0;
  background-color:#fff;
}
.enterName{
  font-size:16px;
  color:#cf4640;
  line-height: 40px;
}
.status {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 20px;
}
.status .leftAngle {
  float: left;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #cf4640 transparent transparent;
}
.status .statusText {
  float: left;
  display: block;
  padding: 0 8px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  background-color: #cf4640;
}
</style>
