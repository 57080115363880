<template>
  <el-dialog
    v-dialogDrag
    title="联系方式"
    :visible.sync="dialogVisible"
    width="360px"
  >
    <!-- <div class="orderConfirm">
      <div class="confirmList">
        <p class="listTitle">请选择下单方式：</p>
        <el-radio-group v-model="method">
          <el-radio :label="1">现货下单</el-radio>
          <el-radio :label="2">履约订金下单</el-radio>
        </el-radio-group>
      </div>
      <div v-if="method === 1" class="confirmList">
        <p class="listTitle">请选择仓储：</p>
        <el-select class="width-100" v-model="warehousId">
          <el-option
            v-for="item in warehousIdOptions"
            :key="item.warehousId"
            :label="item.commodity + ' ' + item.warehousCode"
            :value="item.warehousId"
          ></el-option>
        </el-select>
      </div>
    </div> -->
    <div class="companyInfo">
      <p>联系人:{{ info.contactsName }}</p>
      <p>电话: {{ info.contactsPhone }}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">确认</el-button>
      <!-- <el-button type="primary" :loading="isLoadingOrder" @click="goOrder()">确 定</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      id: null,
      dialogVisible: false,
      isLoadingOrder: false,
      method: 1,
      warehousId: null,
      warehousIdOptions: [],
      right: {
        holdSell: false,
        moneySell: false
      },
      info: {}
    };
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.holdSell = this.isHasRight("trade-pick-stock-order");
          this.right.moneySell = this.isHasRight(
            "trade-pick-margin-order-sell"
          );
        }
      });
    },
    showDialog(id, commodity) {
      this.id = id;
      this.dialogVisible = true;
      this.getWarehousIdOptions(id);
      this.getRights();
    },
    getWarehousIdOptions(commodity) {
      protocolFwd.param_queryCbSupplyNoteDetails.param.supplyId = commodity;
      http
        .postFront(protocolFwd.param_queryCbSupplyNoteDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.info = value;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    goOrder() {
      if (this.method === 1) {
        // 现货下单
        if (this.right.holdSell) {
          if (this.warehousId) {
            this.isLoadingOrder = true;
            protocolFwd.param_pickStockOrder.param.orderId = this.id;
            protocolFwd.param_pickStockOrder.param.warehousId = this.warehousId;
            http
              .postFront(protocolFwd.param_pickStockOrder)
              .then(response => {
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.$confirm("查看订单", "下单成功", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "去看看",
                    cancelButtonText: "取消"
                  })
                    .then(() => {
                      this.$router.push({
                        name: "sellerSalesSpotOrder"
                      });
                    })
                    .catch(action => {});
                  this.isLoadingOrder = false;
                } else {
                  this.$EL_MESSAGE(message);
                  this.isLoadingOrder = false;
                }
                this.dialogVisible = false;
              })
              .catch(() => {
                this.isLoadingOrder = false;
              });
          } else {
            this.$EL_MESSAGE("请选择仓储");
          }
        } else {
          this.$EL_MESSAGE("您无操作权限");
        }
      } else if (this.method === 2) {
        if (this.right.moneySell) {
          this.isLoadingOrder = true;
          protocolFwd.param_pickMarginOrderSell.param.orderId = this.id;
          protocolFwd.param_pickMarginOrderSell.param.type = "SO";
          http
            .postFront(protocolFwd.param_pickMarginOrderSell)
            .then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$confirm("查看订单", "下单成功", {
                  distinguishCancelAndClose: true,
                  confirmButtonText: "去看看",
                  cancelButtonText: "取消"
                })
                  .then(() => {
                    this.$router.push({
                      name: "sellerSalesSpotOrder"
                    });
                  })
                  .catch(action => {});
                this.isLoadingOrder = false;
              } else {
                this.$EL_MESSAGE(message);
                this.isLoadingOrder = false;
              }
              this.dialogVisible = false;
            })
            .catch(() => {
              this.isLoadingOrder = false;
            });
        } else {
          this.$EL_MESSAGE("您无操作权限");
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.orderConfirm .confirmList {
  margin-bottom: 15px;
}
.orderConfirm .confirmList .listTitle {
  margin-bottom: 10px;
}
.companyInfo {
  text-align: center;
}
</style>
